
.app-bar {
    background: white;   
    box-shadow: 0px 4px 4px 0px #0000001A;
    border: 1px solid #0000001A;
    color: white;
    z-index: 1000;
    position: static;
   
  }
  
  .toolbar {
    padding-bottom: 5px; /* You might need to adjust this value */
    box-shadow: 0px 4px 4px 0px #0000001A;
    border: 1px solid #0000001A;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 82px;
   
  }
  
  .brand-text {
    color: #6e4fb3;
    font-family: 'Kaushan Script', cursive;
    font-weight: 400;
    font-size: 32px;
  }
  
  .features {
    display: flex;
    gap: 36px;
    align-items: center;
  }
  
  .feature-text {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
color: #000000;
 
  
    
  }
  
  .download-button {
    min-width: 191px;
    color: white;
    background-color: #6e4fb3;
    border-radius: 24px;
  font-family: Inter;
font-size: 20px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0px;
text-align: center;

  }
  
  /* Media Query for responsiveness */
  @media (max-width: 1441px) {
    .container {
      padding: 24px 80px;
     
    }
  }

  @media (max-width: 360px) {
    .app-bar {
      background: white;   
      /* background-color:yellow; */
      box-shadow: 0px 4px 4px 0px #0000001A;
      border: 1px solid #0000001A;
      color: white;
      z-index: 1000;
      position: static;
   
     
    }
    
    .toolbar {
      padding-bottom: 5px; /* You might need to adjust this value */
      box-shadow: 0px 4px 4px 0px #0000001A;
      border: 1px solid #0000001A;
    }
    .container {
      flex-direction: row; /* Change flex direction to column */
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 17px 16px;
    }
  
    .features {
      display: flex;
      flex-direction: column; 
    
      align-items: center; 
    }
  
    .brand-text {
      color: #6e4fb3;
      font-family: 'Kaushan Script', cursive;
      font-weight: 700;
      font-size: 24px;
    }

    .download-button {
      min-width: 132px;
      color: white;
      background-color: #6e4fb3;
      border-radius: 24px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: center;
      
  
    }

    .feature-text {
      display: none; 
    }
  }
  
  